

















































































import TextInput from '@/components/shared/forms/TextInput.vue';
import TextareaInput from '@/components/shared/forms/TextareaInput.vue';
import FileInput from '@/components/shared/forms/FileInput.vue';
import { ValidationObserver } from 'vee-validate';
import SubmitButton from '@/components/shared/SubmitButton.vue';
import { VendorSettingsPage } from '@/model/vendor-settings-page';
import Vue from 'vue';
import { mapStores } from 'pinia';
import { useVendorsStore } from '@/store/vendors-store';
import { VendorFormData } from '@/model/vendor-form-data';

export default Vue.extend({
  name: 'VendorPublicInformationForm',
  components: {
    FileInput,
    TextareaInput,
    TextInput,
    ValidationObserver,
    SubmitButton
  },
  props: {
    withStepButtons: {
      type: Boolean,
      default: true
    }
  },
  data: () => {
    return {};
  },
  computed: {
    vendorPicture(): string {
      return `${process.env.VUE_APP_API}/vendors/picture/${this.vendor.id}?id=${this.vendor.picture}`;
    },
    ...mapStores(useVendorsStore),
    sendingData(): boolean {
      return this.vendorsStore.saving;
    },
    vendor(): VendorFormData {
      return this.vendorsStore.form;
    },
    errors(): {} {
      return this.vendorsStore.validationErrors;
    }
  },
  watch: {
    vendor() {
      this.$emit('onVendorChange', this.vendor);
    }
  },
  methods: {
    async next(form) {
      form.validate();
      if (form.valid) {
        this.$emit('next');
      }
    },
    async onSubmit(form) {
      form.validate();
      if (form.valid) {
        await this.vendorsStore.edit(this.vendor, VendorSettingsPage.PUBLIC);
      }
    }
  }
});
