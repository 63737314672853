



























import Vue from 'vue';

export default Vue.extend({
  name: 'FileInput',
  props: {
    label: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    optional: {
      type: Boolean,
      default: false
    },
    accept: {
      type: String,
      default: null
    },
    helpText: {
      type: String,
      default: null,
      required: false
    },
    defaultValue: {
      type: [File, Object, Array],
      default: null,
      required: false
    }
  },
  data() {
    return {
      fileNames: null
    };
  },
  created() {
    this.setFilenames(this.defaultValue);
  },
  methods: {
    change(event) {
      this.setFilenames(event.target.files);
      this.$emit('onValueChange', event.target.files);
    },
    setFilenames(value) {
      if (!value) return null;
      if (value.length) {
        this.fileNames = Array.from(value)
          .map((file: any) => file.name)
          .join(', ');
      } else {
        this.fileNames = value.name;
      }
    }
  }
});
